import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import {
	PermissionField,
	PermitOrFeature,
} from 'src/lib/constants/permissions';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { InitializationService } from '../../utility/initialization/initialization.service';
import { PermissionFieldSet } from './permission-field-set';

@Injectable({
	providedIn: 'root',
})
export class PermissionStoreService {
	private _permissionMap = new Map<
		number | string,
		Map<number | string, BehaviorSubject<PermissionFieldSet>>
	>();

	constructor(
		private httpClient: HttpClient,
		initializationService: InitializationService,
	) {
		if (initializationService.permissionParams != null) {
			this.getSubject(
				initializationService.permissionParams.user,
				initializationService.permissionParams.program,
			).next(new PermissionFieldSet(initializationService.permissions));
		}
	}

	public getFieldSet$ = (
		question?: PermissionField,
	): Observable<PermissionFieldSet> => {
		question = question || {};

		return this.getObservable(question.UserId, question.OrgId).pipe(
			filter((x) => x != null),
			take(1),
		);
	};

	private getObservable = (userId: number, orgId: number) => {
		const subject = this.getSubject(userId, orgId);
		subject.pipe(take(1)).subscribe((x) => {
			if (x === undefined) {
				subject.next(null);
				this.fetchPermissions(orgId, userId).subscribe((p) => {
					subject.next(p);
				});
			}
		});

		return subject.asObservable();
	};

	private getSubject = (userId: number, orgId: number) => {
		return this.getOrSetOrgMap(this.getOrSetUserMap(userId), orgId);
	};

	private wrapNull = (value: number): number | string => {
		if (value == null) return 'NULL';
		else return value;
	};

	private getOrSetUserMap = (userId: number) => {
		const wrappedUserId = this.wrapNull(userId);

		if (this._permissionMap.get(wrappedUserId) == null) {
			this._permissionMap.set(
				wrappedUserId,
				new Map<number | string, BehaviorSubject<PermissionFieldSet>>(),
			);
		}

		return this._permissionMap.get(wrappedUserId);
	};

	private getOrSetOrgMap = (
		orgMap: Map<number | string, BehaviorSubject<PermissionFieldSet>>,
		orgId: number,
	) => {
		const wrappedOrgId = this.wrapNull(orgId);

		if (orgMap.get(wrappedOrgId) == null) {
			orgMap.set(
				wrappedOrgId,
				new BehaviorSubject<PermissionFieldSet>(undefined),
			);
		}

		return orgMap.get(wrappedOrgId);
	};

	private fetchPermissions = (
		orgId: number,
		userId: number,
	): Observable<PermissionFieldSet> => {
		const query = buildQueryString({
			program: orgId,
			user: userId,
		});

		return this.httpClient
			.get<PermitOrFeature[]>(`/api/v1/permissions${query}`)
			.pipe(
				catchError((err) => {
					console.error(
						`PermissionStoreService fetchPermissions errored with query ${query}`,
						err,
					);
					return of([] as PermitOrFeature[]);
				}),
				map((x) => new PermissionFieldSet(x)),
			);
	};
}
