import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	SurveyModel,
	SurveyResponseArgument,
	SurveyStudentResponseModel,
} from './survey.model';

@Injectable({
	providedIn: 'root',
})
export class SurveyService {
	constructor(private httpClient: HttpClient) {}

	public assignSurvey = (surveyId: number, ids: number[]): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/response/survey/${surveyId}/assign`, {
				student_uids: ids,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '28169722',
				}),
			);
	};

	public getSurvey = (surveyId: number): Observable<SurveyModel> => {
		return this.httpClient.get<any>(`/api/v1/response/survey/${surveyId}`).pipe(
			mapResponse((r) => plainToInstance(SurveyModel, r.survey), {
				errorCode: 'DA9A3055',
			}),
		);
	};

	public createResponse = (
		studentId: number,
		response: SurveyResponseArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/student/${studentId}/response/survey_response/create`,
				response,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'BFB3252E',
				}),
			);
	};

	public completeSurvey = (
		responseId: number,
		response: SurveyResponseArgument,
	) => {
		return this.httpClient
			.post<any>(
				`/api/v1/student/my/response/survey_response/${responseId}/complete`,
				response,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'FC1B69A2',
				}),
			);
	};

	public getStudentResponses = (): Observable<SurveyStudentResponseModel[]> => {
		return this.httpClient
			.get<any>(`api/v1/student//my/response/survey_response`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(SurveyStudentResponseModel, r.responses as any[]),
					{
						errorCode: '925A1C67',
					},
				),
			);
	};
	public getResponses = (
		studentId: number,
	): Observable<SurveyStudentResponseModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/student/${studentId}/response/survey_response`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(SurveyStudentResponseModel, r.responses as any[]),
					{
						errorCode: '6715C43E',
					},
				),
			);
	};
}
