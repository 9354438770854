import { NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, NEVER, combineLatest, timer } from 'rxjs';
import { skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { getEnvironment } from 'src/lib/environment/environment';
import { StudentsService } from 'src/lib/services/api/students/students.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { SidenavTabberTab } from 'src/lib/templates/layout/sidenav-tabber/sidenav-tabber.config';
import { mergeStrings } from 'src/lib/utilities/array';
import { convertToInt } from 'src/lib/utilities/convert';
import {
	hasSuperMasquerade,
	superMasqueradeAsUser,
} from 'src/lib/utilities/gradally';
import { buildUserName } from 'src/lib/utilities/string';
import { SidenavTabberComponent } from '../../../../templates/layout/sidenav-tabber/sidenav-tabber.component';
import { SpinWhileDirective } from '../../../../templates/layout/spin-while/spin-while.directive';
import { ProfileImageUploadModalComponent } from '../profile-image-uploader/profile-image-upload-modal.component';
import { ProfileListenerService } from '../profile-listener.service';

const tabOrder = [
	{ key: 'profile', title: 'General Info' },
	{ key: 'contact', title: 'Contact' },
	{ key: 'family', title: 'Family and Support' },
	{ key: 'security', title: 'Security' },
	{ key: 'subscriptions', title: 'Subscriptions' },
	{ key: 'settings', title: 'Settings' },
	{ key: 'account', title: 'Account' },
	{ key: 'activity', title: 'Activity' },
	{ key: 'agreements', path: 'agreements', title: 'Agreements' },
	{ key: 'sitemap', path: 'sitemap', title: 'Sitemap' },
	{
		key: 'accessibility-statement',
		path: 'accessibility-statement',
		title: 'Accessibility Statement',
	},
	{ key: 'drupal-edit', path: 'drupal', title: 'Drupal' },
];

@Component({
	selector: 'ae-profile-navigation',
	templateUrl: './profile-navigation.component.html',
	styleUrls: ['./profile-navigation.component.scss'],
	providers: [ProfileListenerService],
	standalone: true,
	imports: [SidenavTabberComponent, SpinWhileDirective, NgStyle],
})
export class ProfileNavigationComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public isStudentApp = getEnvironment().isApp('student');

	public loading: boolean = true;
	public error: boolean = false;

	private userId: number;
	public userFullName: string;
	public userProfileImagePath: string;

	public notFound: boolean = false;
	public noPermission: boolean = false;

	public canEditStudent: boolean;
	public status: boolean = true;

	public defaultProfileImageUrl = `${
		getEnvironment().assets
	}/images/person_default.png`;

	public tabs: SidenavTabberTab[];

	get isOwnProfilePage(): boolean {
		return this.userId === this.userStoreService.currentUserUid;
	}

	hasSuperMasquerade = hasSuperMasquerade;
	masqueradeLink: string;

	constructor(
		private route: ActivatedRoute,
		private userStoreService: UserStoreService,
		private modalService: NgbModal,
		private profileListenerService: ProfileListenerService,
		private permissionService: PermissionStoreService,
		private title: Title,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private studentService: StudentsService,
	) {}

	ngOnInit() {
		this.getProfileData();

		this.profileListenerService
			.listenSignal$('user.name')
			.pipe(
				switchMap(() => this.updateName$()),
				takeUntil(this._unsubscribe$),
			)
			.subscribe({ error: (errors) => console.error(mergeStrings(errors)) });

		this.studentService.getLinks(this.userId).subscribe((links) => {
			this.masqueradeLink = links.find((x) => x.id === 'masquerade')?.link;
		});
	}

	public getProfileData = () => {
		this.loading = true;
		this.error = false;

		this.route.params
			.pipe(
				switchMap((params) => {
					this.userId = convertToInt(params.userId);
					if (this.userId == null) {
						this.router.navigate(['./', this.userStoreService.currentUserUid], {
							queryParamsHandling: 'preserve',
							replaceUrl: true,
							relativeTo: this.activatedRoute,
						});
						return NEVER;
					}
					return combineLatest([
						this.userStoreService.profileTabs$(this.userId),
						this.permissionService.getFieldSet$(),
						this.updateName$(),
					]);
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe({
				next: ([allowedTabs, permits]) => {
					// Permission Check
					this.tabs = tabOrder.map((tab) => {
						return {
							key: tab.key,
							name: tab.title,
							path: `/profile/${this.userId}/${tab.path ?? tab.key}`,
							hasPermission: allowedTabs.find((t) => t === tab.key) != null,
						};
					});

					// Sitemap is permissioned separately as it's a per app system
					if (getEnvironment().isApp('student')) {
						const sitemap = this.tabs.find((t) => t.key === 'sitemap');
						if (sitemap) {
							sitemap.hasPermission = true;
						}

						const accessibilityStatement = this.tabs.find(
							(t) => t.key === 'accessibility-statement',
						);
						if (accessibilityStatement) {
							accessibilityStatement.hasPermission = true;
						}
					}

					if (permits.canDo(Permits['ga_student|view student agreements'])) {
						const agreements = this.tabs.find((t) => t.key === 'agreements');
						if (agreements) {
							agreements.hasPermission = true;
						}
					}

					this.loading = false;
				},
				error: (errors) => {
					console.error(mergeStrings(errors));
					this.error = true;
				},
			});
	};

	private updateName$ = () => {
		if (!this.userId) return NEVER;

		return this.userStoreService.userData$(this.userId).pipe(
			take(1),
			tap((user) => {
				if (user.profile) {
					this.userFullName = buildUserName(
						user.profile.profile_first_name,
						user.profile.profile_last_name,
						user.profile.profile_preferred_name,
					).fullName;
				}
				this.status = user.user?.status ?? true;

				this.userProfileImagePath = user.user?.profile_picture_high_res;

				this.title.setTitle(
					`${this.userFullName}'s Profile | Graduation Alliance`,
				);
			}),
		);
	};

	public openUploadImageModal = () => {
		// Other users cant edit your picture
		if (!this.isOwnProfilePage) {
			return false;
		}

		const ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			size: 'md',
		};
		const editModal = this.modalService.open(
			ProfileImageUploadModalComponent,
			ngbModalOptions,
		);

		(
			editModal.componentInstance as ProfileImageUploadModalComponent
		).bindModalData({
			userId: this.userId,
			currentImg: this.userProfileImagePath,
		});

		editModal.result
			.then(() => {
				timer(500) // Wait for the image to upload
					.pipe(
						switchMap(
							() => this.userStoreService.userData$(this.userId, false), // Force a refresh
						),
						skip(1),
						take(1),
					)
					.subscribe({
						next: (data) => {
							this.userProfileImagePath =
								data?.user?.profile_picture ?? this.userProfileImagePath;
						},
						error: (errors) => {
							console.error(mergeStrings(errors));
							// Also do nothing
						},
					});
			})
			.catch(() => {
				// Do nothing
			});

		return true;
	};

	public profileImageError = (ele: HTMLElement) => {
		ele.setAttribute('src', this.defaultProfileImageUrl);
	};

	public superMasquerade = () => {
		superMasqueradeAsUser(this.userId);
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
