const monthLengths = [31,
// January - 31 days
28,
// February - 28 days in a common year and 29 days in leap years
31,
// March - 31 days
30,
// April - 30 days
31,
// May - 31 days
30,
// June - 30 days
31,
// July - 31 days
31,
// August - 31 days
30,
// September - 30 days
31,
// October - 31 days
30,
// November - 30 days
31 // December - 31 days
];
export default monthLengths;

// 28 - feb
// 30 - april, june, sept, nov
// 31 - jan, march, may, july, aug, oct, dec