import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UserStoreService } from '../services/stores/users/user/user-store.service';
import { isArray } from '../utilities/compare';
import { buildUserName } from '../utilities/string';

@Pipe({
	name: 'userName',
	pure: false,
	standalone: true,
})
export class UserNamePipe implements PipeTransform {
	private lastValue: any | undefined;
	private formattedValue: string | undefined;

	constructor(
		private readonly userStoreService: UserStoreService,
		private readonly cdref: ChangeDetectorRef,
	) {}

	transform(
		input: number | [string, string] | [string, string, string],
		returnVal: 'first' | 'last' | 'full' = 'full',
		usePreferred: boolean = true,
	): string | undefined {
		if (!input || input === this.lastValue) {
			return this.formattedValue;
		}

		this.lastValue = input;

		if (isArray(input)) {
			this.formattedValue = this.getValue(
				buildUserName(input[0], input[1], usePreferred ? input[2] : null),
				returnVal,
			);
		} else {
			this.userStoreService.userData$(input).subscribe((r) => {
				this.formattedValue = this.getValue(
					buildUserName(
						r.profile.profile_first_name,
						r.profile.profile_last_name,
						usePreferred ? r.profile.profile_preferred_name : null,
					),
					returnVal,
				);
				this.cdref.markForCheck();
			});
		}

		return this.formattedValue;
	}

	private getValue = (
		build: { names: [string, string]; fullName: string },
		retValue: 'first' | 'last' | 'full',
	): string => {
		if (retValue === 'first') {
			return build.names[0];
		} else if (retValue === 'last') {
			return build.names[1];
		} else {
			return build.fullName;
		}
	};
}
