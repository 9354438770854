//https://www.timeanddate.com/calendar/aboutseasons.html
// Spring - from March 1 to May 31;
// Summer - from June 1 to August 31;
// Fall (autumn) - from September 1 to November 30; and,
// Winter - from December 1 to February 28 (February 29 in a leap year).
const north = [['spring', 2, 1], ['summer', 5, 1], ['fall', 8, 1], ['autumn', 8, 1], ['winter', 11, 1] //dec 1
];
const south = [['fall', 2, 1], ['autumn', 2, 1], ['winter', 5, 1], ['spring', 8, 1], ['summer', 11, 1] //dec 1
];
export default {
  north,
  south
};